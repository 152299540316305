import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import "../public/css/styles.scss";
import "../public/css/responsesive.scss";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueExcelXlsx from "vue-excel-xlsx";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { arrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'


// library.add(arrowRightFromBracket);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VueSweetalert2)
  .use(store)
  .use(VueExcelXlsx)
  .use(router)
  .use(ElementPlus)
  .mount("#app");
