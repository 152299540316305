import * as VueRouter from "vue-router";

const routes = [
  {
    path: "/Scbhottopic",
    name: "scbhoptopic",
    component: () =>
      import("../views/Scbhottopic.vue"),
  },
  {
    path: "/Login",
    name: "login",
    component: () =>
      import("../views/Login.vue"),
  },
  {
    path: "/Manageusers",
    name: "manageusers",
    component: () =>
      import("../views/Manageusers.vue"),
  },
  {
    path: '/Notfound',
    name: '404Notfound',
    component: () => import('../views/Notfound.vue'),
  },
  {
    path: "/",
    redirect: "/Login"
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/Notfound',
  }
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

export default router;
