<template>
  <el-menu
    :default-active="activeIndex"
    mode="horizontal"
    class="el-menu-demo"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
    @select="handleSelect"
    style="justify-content: flex-end"
  >
    <el-menu-item index="1"><el-icon><Warning /></el-icon>SCB HotTopic</el-menu-item>
    <el-menu-item v-if="disabled == false" index="2"><el-icon><List /></el-icon>Manage Users</el-menu-item>
    <el-menu-item index="3"><el-icon><User /></el-icon><span>Logout</span></el-menu-item>
  </el-menu>
</template>

<script>
import { Warning, List, User } from "@element-plus/icons";
export default {
  name: "Header-page",
  components: { Warning, List, User },
  mounted() {
    this.CheckRoleUser();
    this.$nextTick(() => this.CheckPageIndex())
  },
  data() {
    return {
      disabled:false,
      activeIndex: "1",
      RouterPath:""
    };
  },
  methods: {
    //CheckRoleUser
    CheckRoleUser(){
      this.GetroleUser = localStorage.getItem('roleLevel');
      if(this.GetroleUser == 9){
          this.disabled=false
      }else{
          this.disabled=true
      }
    },

    //CheckPageIndex
     CheckPageIndex(){
      this.RouterPath = this.$store.getters["Route_path"]
      
      if(this.RouterPath === "/Scbhottopic"){
         this.activeIndex="1"
        //  console.log('1');
      }else if(this.RouterPath === "/Manageusers"){
         this.activeIndex="2"
        //  console.log('2');
      }
    },

    handleSelect(key) {
      //console.log('tet1', this.$store.getters["Route_path"])
      if (key == 1) {
        this.$router.push({ name: "scbhoptopic" });
      } else if (key == 2){
        this.$router.push({ name: "manageusers" });
      }else{
        this.$router.push({ name: "login" });
        this.$store.dispatch("doLogout");
      }
    },
  },
};
</script>

<style></style>
