import router from "@/router";
import Axios from "axios";
import Swal from "sweetalert2";

const isLoggedIn = () => {
  let token = localStorage.getItem('userID');
  return token != null;
};

const login = async check_user_pass => {
  const url = `${process.env.VUE_APP_API_URL}Users/login`;
  try{
    let result = await Axios.post(url, check_user_pass);
  if (result.data.Message == "Success") {
    localStorage.setItem('id', result.data.Data[0].id);
    localStorage.setItem('username', result.data.Data[0].username);
    localStorage.setItem('fullname', result.data.Data[0].fullname);
    localStorage.setItem('userID', result.data.Data[0].userID);
    localStorage.setItem('roleID', result.data.Data[0].role[0].id);
    localStorage.setItem('roleLevel', result.data.Data[0].role[0].roleLevel);
    localStorage.setItem('roleName', result.data.Data[0].role[0].roleName);
    localStorage.setItem('roleActive', result.data.Data[0].role[0].active);
    return true;
  } else {
    Swal.fire(
      'Error !',
      'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง',
      'error'
    )
    return false;
  }
  }catch(err){
    // console.log(err)
    Swal.fire(
      'Error !',
      'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง',
      'error'
    )
    return false;
  }
  
};

  const logoff = () => {
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    localStorage.removeItem('fullname');
    localStorage.removeItem('userID');
    localStorage.removeItem('roleID');
    localStorage.removeItem('roleLevel');
    localStorage.removeItem('roleName');
    localStorage.removeItem('roleActive');
    router.push("/Login");
  };

  export default{
    isLoggedIn,
    login,
    logoff
  }