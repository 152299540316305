<template>

    <Header v-if="$store.state.isLogged" />
  
    <ContentMain />
</template>

<script>
import Header from "@/components/core/Header";
import ContentMain from "./components/core/ContentMain";

export default {
  name: "App",
  components: {
    Header,
    ContentMain,
  },

  mounted() {
    this.$store.dispatch({ type: "restoreLogin" });
    // if(localStorage.getItem("userID")!=null){
    //   inactivityTime();
    // }
      inactivityTime();
  },
  data: () => ({
    //


  }),
};
var inactivityTime = function () {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    function logout() {
        localStorage.clear();
        location.href = '/Login'
    }

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(logout, 900000)
        // 1000 milliseconds = 1 second
    }
};
</script>
