import { createStore } from "vuex";
import api from "@/services/api";
import router from "@/router";
import { ElLoading } from "element-plus";
const store = createStore({
  state: {
    isLogged: false,
    username:"",
    Routepath:"",
    // loading:false
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.isLogged = true;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
    },
    SET_USERNAME(state, value) {
      state.username = value;
    },
    SET_ROUTE_PATH(state, route) {
      state.Routepath = route;
    },
  },
  getters: {
    isLogin(state) {
      return state.isLogged;
    },
    username(state) {
      return `${state.username}`;
    },
    Route_path(state) {
      return state.Routepath;
    },
  },
  actions: {
    inputRoutepath({ commit }, { RT }) {
      commit("SET_ROUTE_PATH", RT);
    },
    async doLogin({ commit, dispatch }, { username,password }) {
      let loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let result = await api.login({ username, password });
      if (result == true) {
        commit("SET_LOGGED_IN");
        commit("SET_USERNAME", username);
        loading.close();
        router.push("/Scbhottopic");
      } else {
        loading.close();
        dispatch("doLogout", {});
      }
    },
    doLogout({ commit }) {
      localStorage.clear();
      api.logoff();
      commit("SET_LOGGED_OUT");
      commit("SET_USERNAME", "");
    },

    restoreLogin({ commit }) {
      if (api.isLoggedIn() == true) {
        let username = localStorage.getItem("username");
        commit("SET_LOGGED_IN");
        commit("SET_USERNAME", username);
      }
    },
  },
});

export default store;
